<template>
  <div class="player-box is-paddingless has-background-light" style="">

      <!-- ////////////// --> <!-- ////////////// -->
      <!-- PLAYER TOP BAR --> <!-- ////////////// -->
      <div class="columns player-single-top-bar is-paddingless is-marginless is-vcentered">

        <!-- UNCOLLAPSED -->
        <div class="column is-paddingless is-narrow">
          <slot></slot>
        </div>

        <!-- ONLINE STATUS -->
        <div class="column is-narrow player-online-status">
          <i
            class="fas fa-circle"
            :class="[isConnectedToHub ? 'has-text-primary' : 'has-text-danger']"
            style="margin-right:10px;">
          </i>
          <strong>{{isConnectedToHub ? 'Online' : 'Offline'}}</strong>
        </div>

        <!-- PLAYER NAME -->
        <div class="column is-paddingless player-name">
          <input class="input" type="text" v-on:keyup.enter="playerSaveName" v-model="player.meta.name">
        </div>

      </div>
      <!-- ////////////// --> <!-- ////////////// -->


      <div class="player-single-info-area" v-if="uncollapsed">

        <b-tabs size="" class="block">
            <b-tab-item label="Inhalt" icon="image">

              <div class="column">
                <label class="label">Channel</label>
                <div class="field has-addons">
                  <div class="control is-expanded">
                    <div class="select is-fullwidth">
                      <select v-model="selectedChannelId">
                        <option
                          v-for="(channel, i) in availableChannels"
                          :value="channel._id" :key="channel._id">{{channel._shortid}} : {{channel.meta.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="control">
                    <a
                      class="button is-dark"
                      :class="{'is-loading':isSaving}"
                      :disabled="player.channel && selectedChannelId == player.channel._id"
                      @click="playerSaveChannel">
                      Speichern</a>
                  </div>
                </div>
                <label class="label">Region</label>
                <div class="field has-addons">
                  <div class="control is-expanded">
                    <div class="select is-fullwidth">
                      <select v-model="selectedRegionId">
                        <option value="">Keine Region</option>
                        <option
                          v-for="(region, i) in availableRegions"
                          :value="region._id" :key="region._id">{{region.meta.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="control">
                    <a
                      class="button is-dark"
                      :class="{'is-loading':isSaving}"
                      :disabled="player.parentOrganization && selectedRegionId == player.parentOrganization"
                      @click="playerSaveRegion">
                      Speichern</a>
                  </div>
                </div>
              </div>

            </b-tab-item>
            <b-tab-item label="Live View" icon="video">
              <div class="column">
                <img :src="`https://images.containerapp1.dev/s/${player._id}?time=${liveViewTime}`" />
              </div>
            </b-tab-item>
            <b-tab-item label="Kiosk Mode" icon="monitor">
              <b-switch
                v-model="kioskMode"
                passive-type='is-danger'
                type="is-success">
                    Kiosk Modus
                </b-switch>
                <label class="label">Mobile Page</label>
                <div class="field has-addons">
                  <div class="control is-expanded">
                    <div class="select is-fullwidth">
                      <select v-model="selectedMobileId">
                        <option
                          v-for="(channel, i) in availableMobilePages"
                          :value="channel._id" :key="channel._id">{{channel._shortid}} : {{channel.meta.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="control">
                    <a
                      class="button is-dark"
                      :class="{'is-loading':isSaving}"
                      :disabled="player.mobilePage && selectedMobileId == player.mobilePage._id"
                      @click="playerSaveMobileChannel">
                      Speichern</a>
                  </div>
                  
                </div>
            </b-tab-item>
            <b-tab-item label="Email Alert" icon="bell">
              <!-- <div class="column offline-alert-setting"> -->
                <!-- <label class="checkbox">
                  <input type="checkbox" v-model="offlineNotification" @change="playerSaveAlertSetting">
                  <strong> Offline Email-Benachrichtigung aktivieren</strong>
                  <p class="help has-text-danger">Experimentelles Feature. Erhalte eine Benachrichtung sobald<br>dieser Player länger als 5 Minuten offline ist.</p>
                </label> -->
              <!-- </div> -->
              <b-tooltip type="is-dark" multilined label="Aktivierst du die E-Mail Benachrichtigung erhältst du eine E-Mail wenn der Player länger als 10 Minuten nicht mehr mit unseren Server verbunden ist (=Offline)">
              <b-field class="mb-5">
                <b-switch
                v-model="offlineNotification"
                passive-type='is-danger'
                type="is-success">
                    E-Mail Benachrichtung
                </b-switch>
              </b-field></b-tooltip>

              <b-field label="Email">
                <b-input type="email"
                  placeholder="E-Mail"
                  icon="email"
                  v-model="alertEmail"
                  maxlength="60">
                </b-input>
              </b-field>

              <a
                class="button is-black"
                :class="{'is-loading':isLoading}"
                @click="playerSaveAlertSetting">
                Speichern
              </a>
              

            </b-tab-item>
            <b-tab-item label="Manage" icon="cog">


                <p><strong>{{ player._id }}</strong></p>
                <p style="margin-bottom:20px;"><strong>{{ player._shortid }}</strong></p>
                <div class="buttons">
                  <a
                    class="button is-primary"
                    :class="{'is-loading':isLoading}"
                    @click="playerSendMessage(player._id, 'reload')">
                    <strong>Refresh</strong></a>
                  <a
                    class="button is-danger"
                    :class="{'is-loading':isLoading}"
                    @click="playerSendMessage(player._id, 'reboot')">
                    <strong>Neustarten</strong></a>
                  <a
                    class="button is-black is-outlined"
                    :class="{'is-loading':isLoading}"
                    @click="openConfirmModalForRemoval">
                    Von Account entfernen</a>
                </div>

            </b-tab-item>
        </b-tabs>

      </div>
      <!-- ////////////// --> <!-- ////////////// -->

      <!-- MODAL -->
      <div class="modal" :class="{'is-active' : confirmModalIsOpen}">
        <div class="modal-background" @click="closeConfirmModalForRemoval"></div>
        <div class="modal-content">
          <div class="box">
            <h1 class="title">Du willst diesen Player wirklich dauerhaft von deinem Account entfernen?</h1>
            <div class="buttons">
              <a class="button is-danger" @click="playerRemove(player._id)">Ja, Player entfernen !</a>
              <a class="button is-outlined is-black" @click="closeConfirmModalForRemoval">Nein, Player behalten</a>
            </div>
          </div>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="closeConfirmModalForRemoval"></button>
      </div>

  </div>
</template>

<script>

  export default {
    props: ['player', 'uncollapsed', 'liveViewTime'],
    data() {
      return {
        isLoading : false,
        isSaving  : false,
        isConnectedToHub : false,
        selectedChannelId : this.player.channel ? this.player.channel._id : '',
        selectedRegionId : this.player.parentOrganization ? this.player.parentOrganization : '',
        selectedMobileId : this.player.mobilePage ? this.player.mobilePage : '',
        kioskMode : this.player.kioskMode ? this.player.kioskMode : false,
        offlineNotification : this.player.alert.active ? this.player.alert.active : false,
        lastHeartbeat : this.player.alert.lastHeartbeat ? this.player.alert.lastHearbeat : false,
        alertEmail : this.player.alert.email ? this.player.alert.email : '',
        currentSystemStatus : null,
        confirmModalIsOpen : false,
        // liveViewTime : Date.now(),
        // liveViewInterval : null,
      }
    },
    computed: {
      availableChannels() { return this.$store.getters["assets/channels"] },
      availableRegions () { return this.$store.getters["organization/public"] },
      availableMobilePages () { return this.$store.getters["assets/mobile-pages"] },
      playerHasChannel() {
        let p = this.player;
        return p.channel && p.channel.meta;
      }
    },
    methods: {
      openConfirmModalForRemoval() {
        this.confirmModalIsOpen = true;
      },
      closeConfirmModalForRemoval() {
        this.confirmModalIsOpen = false;
      },
      playerRemove(playerid) {
        this.closeConfirmModalForRemoval();
        this.$store.dispatch('players/REMOVE', playerid);
      },
      playerSendMessage(playerid, name) {
        this.isLoading = true;
        this.$store.dispatch('players/SENDMESSAGE', { playerid, name, data : {} })
        .then(bool => {

        })
        .finally(() => {
          this.isLoading = false;
        });
      },
      playerCheckIfOnline(playerid) {
        this.isLoading = true;
        this.$store.dispatch('players/CHECKIFONLINE', { playerid })
        .then(bool => {
          this.isConnectedToHub = bool;
        })
        .finally(() => {
          this.isLoading = false;
        });
      },
      playerGetSystemStatus(playerid) {
        this.isLoading = true;
        this.$store.dispatch('players/GETSYSTEMSTATUS', { playerid })
        .then(payload => {
          this.currentSystemStatus = payload;
        })
        .finally(() => {
          this.isLoading = false;
        });
      },
      playerSaveChannel() {

        if (this.player.channel && this.selectedChannelId == this.player.channel._id) {
          return
        } else {
          this.isSaving = true;
          this.$store.dispatch('players/SAVECHANNEL', {
            playerid : this.player._id,
            channelid : this.selectedChannelId
          })
          .then(bool => {

          })
          .finally(() => {
            this.isSaving = false;
          });
        }

      },
      playerSaveMobileChannel() {

        if (this.player.mobilePage && this.selectedMobileId == this.player.mobilePage._id) {
          return
        } else {
          this.isSaving = true;
          this.$store.dispatch('players/SAVEMOBILEPAGE', {
            playerid : this.player._id,
            mobilePageId : this.selectedMobileId,
            kioskMode : this.kioskMode
          })
          .then(bool => {

          })
          .finally(() => {
            this.isSaving = false;
          });
        }

      },
      playerSaveRegion() {
        if (this.player.parentOrganization && this.selectedRegionId == this.player.parentOrganization) {
          return 
        } else {
          this.isSaving = true;
          let parentOrganization = this.selectedRegionId !== "" ? this.selectedRegionId : null;
          this.$store.dispatch('players/SAVEREGION', {
            playerid : this.player._id,
            parentOrganization : parentOrganization
          })
          .then(bool => {

          })
          .finally(() => {
            this.isSaving = false;
          })
        }
      },
      playerSaveName() {
        this.isSaving = true;
        this.$store.dispatch('players/SAVENAME', {
          playerid : this.player._id,
          newname  : this.player.meta.name
        })
        .then(bool => {

        })
        .finally(() => {
          this.isSaving = false;
        });
      },
      playerSaveAlertSetting(val) {
        this.isSaving = true;
        // console.log(this.player._id, this.offlineNotification, this.alertEmail);
        this.$store.dispatch('players/SAVEALERTSETTING', {
          playerid : this.player._id,
          active  : this.offlineNotification,
          email : this.alertEmail
        })
        .then(bool => {

        })
        .finally(() => {
          this.isSaving = false;
        });
      }
    },
    mounted() {
      this.playerCheckIfOnline(this.player._id);
    }
  }

</script>

<style scoped>

  .player-box {
    margin-bottom:20px;
    border-radius:5px;
    overflow:hidden;
  }

  .player-single-top-bar {
    background:rgba(0,0,0,0.1);
  }

  .player-single-info-area {
    padding:30px;
  }

  .player-online-status {
    border-right: 1px solid rgba(0,0,0,0.3);
    padding-left:20px;
    padding-right:20px;
  }

  .player-name {
    margin-left: 20px;
    margin-right: 20px;
    max-width:250px;
  }

  .player-name input {
    background:none;
    border:none;
    cursor: pointer;
    box-shadow: none;
  }

  .player-name input:hover {
    background:rgba(0,0,0,0.1);
    border:none;
  }

  .player-name input:focus {
    background:white;
  }

  .offline-alert-setting {
    background:rgba(255,255,255,0.8);
    border: 1px solid rgba(0,0,0,0.1);
    border-radius:5px;
  }

</style>
